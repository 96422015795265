<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  label="LBLREMOVE"
                  icon="remove" />
                <c-btn
                  v-if="editable"
                  label="LBLSAVE"
                  icon="save" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  type="edit"
                  :required="true"
                  :comboItems="col0Items"
                  itemText="codeName"
                  itemValue="code"
                  label="배출시설코드"
                  name="col1"
                  v-model="data.col1">
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="배출시설 명"
                  name="col2"
                  v-model="data.col2">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  type="edit"
                  :required="true"
                  :comboItems="col2Items"
                  itemText="codeName"
                  itemValue="code"
                  label="배출활동"
                  name="col02"
                  v-model="data.col02">
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="소규모 배출시설"
                  name="col3"
                  v-model="data.col3"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="시설용량 값"
                  name="col4"
                  type="number"
                  v-model="data.col4">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :comboItems="tierItems1"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  label="시설용량 단위"
                  name="col5"
                  v-model="data.col5"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="세부시설용량 값"
                  name="col6"
                  type="number"
                  v-model="data.col6">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :comboItems="tierItems1"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  label="세부시설용량 단위"
                  name="col7"
                  v-model="data.col7"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  label="가동일자"
                  name="col8"
                  v-model="data.col8"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="일일 평균가동시간"
                  name="col9"
                  type="number"
                  suffix="h/d"
                  v-model="data.col9">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="연간 가동일수"
                  name="col10"
                  type="number"
                  suffix="d/y"
                  v-model="data.col10">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="부하율"
                  name="col11"
                  type="number"
                  suffix="%"
                  v-model="data.col11">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :comboItems="tierItems2"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  label="배출량에 따른 시설규모"
                  name="col12"
                  v-model="data.col12"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept
                  :required="true"
                  :disabled="disabled"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="할당대상여부"
                  name="col13"
                  v-model="data.col13"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :comboItems="fuelItems3"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="신설/증설/폐쇄 여부"
                  name="col14"
                  v-model="data.col14"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  label="계획기간"
                  :range="true"
                  name="col15"
                  v-model="data.col15"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  label="발생일"
                  name="col16"
                  v-model="data.col16"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="신설/증설/폐쇄 사유"
                  name="col17"
                  v-model="data.col17">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            ref="table"
            title="투입 원/연료"
            tableId="processManual"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="false"
            :expandAll="true"
            selection="multiple"
            rowKey="col0"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="LBLADD" icon="add" />
                <c-btn label="LBLREMOVE" icon="remove" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="table2"
            title="생산제품"
            tableId="processManual"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="grid2.data"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="false"
            :expandAll="true"
            selection="multiple"
            rowKey="col0"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="LBLADD" icon="add" />
                <c-btn label="LBLREMOVE" icon="remove" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          reportId: '',
          isFullScreen: false,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      col0Items: [
        { code: '0005', codeName: '공정 연소시설' },
        { code: '0021', codeName: '발전용 내연기관' },
        { code: '0055', codeName: '일반 보일러시설' },
      ],
      tierItems1: [
        { code: '1', codeName: 'Kg' },
        { code: '2', codeName: 'Ton' },
      ],
      col2Items: [
        { code: '1', codeName: '고체연료연소' },
        { code: '2', codeName: '기체연료연소' },
        { code: '3', codeName: '액체연료연소' },
      ],
      tierItems: [
        { code: '1', codeName: 'Tier 1' },
        { code: '2', codeName: 'Tier 2' },
        { code: '3', codeName: 'Tier 3' },
        { code: '4', codeName: 'Tier 4' },
      ],
      tierItems2: [
        { code: '1', codeName: 'A그룹' },
        { code: '2', codeName: 'B그룹' },
        { code: '3', codeName: 'C그룹' },
      ],
      fuelItems: [
        { code: '1', codeName: '등류' },
        { code: '2', codeName: '경유' },
        { code: '3', codeName: 'LPG' },
      ],
      fuelItems3: [
        { code: '1', codeName: '신설' },
        { code: '2', codeName: '증설' },
        { code: '3', codeName: '폐쇄' },
      ],
      data: {
        plantCd: '1000',
        col1: '1',
        col2: '2',
        col3: 'A-#2호 보일러',
        col4: '1',
        col5: '2',
        col6: '1',
        col7: '2021-11-03',
        deptCd: 'D10005',
      },
      grid: {
        columns: [
          {
            name: 'col0',
            field: 'col0',
            label: '연/원료명칭',
            align: 'center',
            type: 'text',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '연/원료 구분',
            align: 'center',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '연료' },
              { code: '2', codeName: '원료' },
            ],
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '연료구분',
            align: 'center',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '액체연료연소' },
              { code: '2', codeName: '고체연료연소' },
            ],
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'col3',
            field: 'col3',
            label: '발열량',
            align: 'center',
            sortable: true,
            type: 'number',
            style: 'width: 120px',
          },
          {
            name: 'col4',
            field: 'col4',
            label: 'CO2 배출계수<br>(tCO2/TJ)',
            align: 'center',
            sortable: true,
            type: 'number',
            style: 'width: 120px',
          },
          {
            name: 'col5',
            field: 'col5',
            label: 'CH4 배출계수<br>(tCH4/TJ)',
            align: 'center',
            sortable: true,
            type: 'number',
            style: 'width: 120px',
          },
          {
            name: 'col6',
            field: 'col6',
            label: 'N2O 배출계수<br>(tN2O/TJ)',
            align: 'center',
            sortable: true,
            type: 'number',
            style: 'width: 120px',
          },
        ],
        data: [],
        height: '300px',
      },
      grid2: {
        columns: [
          {
            name: 'col0',
            field: 'col0',
            label: '제품명',
            align: 'center',
            type: 'text',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '제품단위',
            align: 'center',
            type: 'select',
            comboItems: [
              { code: '1', codeName: 'M/T' },
              { code: '2', codeName: 'kL' },
              { code: '2', codeName: 'kg' },
            ],
            sortable: true,
            style: 'width: 100px',
          },
        ],
        data: [],
        height: '300px',
      },
      editable: true,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
      this.data = {
        col1: '0005', 
        col2: '공정 연소시설', 
        col02: '1', 
        plantCd: '1000',
        col3: 'Y', 
        col4: '3000', 
        col5: '2', 
        col6: '1200', 
        col7: '2', 
        col8: '2021-01=21', 
        col9: '10', 
        col10: '300', 
        col11: '0.1', 
        col12: '1', 
        col13: 'Y', 
        col14: null,
        deptCd: 'D10005'
      }
      this.grid.data = [
        {
          col0: '나프타',
          col1: '1', 
          col2: '2', 
          col02: '1', 
          col3: '100', 
          col4: '0.0021', 
          col5: '0.0127', 
          col6: '0.0006', 
        },
      ];
      this.grid2.data = [
        {
          col0: '콜타르',
          col1: '1', 
        },
      ];
    },
  }
};
</script>